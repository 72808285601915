.bar-chart-grid {
  width: 100% !important;
  text-align: center;
  padding: 12px 24px 24px !important;
}

.bar-chart-title {
  text-align: left;
}

.bar-chart-container {
  position: relative;
}

.bar-chart-bars {
  display: flex;
}

.bar-chart-bar {
  height: 32px;
  border: 1px solid #fff;
  margin-right: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: filter 0.3s ease;
}

.bar-chart-bar:hover {
  filter: brightness(50%);
}

.bar-chart-legend {
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  color: #333;
}

.bar-chart-legend-item {
  margin: 0 2px;
}

.bar-chart-tooltip {
  padding: 5px;
  color: #333;
}

.header-content {
  display: flex;
  justify-content: center;
}

.header-content-container {
  max-width: 1024px;
  width: 100%;
}

.header-logo {
  max-width: 100%;
  height: 60px;
}

.content {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.content-container {
  max-width: 1024px;
  width: 100%;
}

.page-status {
  margin-bottom: 20px;
}

.bar-chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bar-status {
  font-size: 0.8rem;
  padding-right: 10px;
}

.bar-status.operational {
  color: rgb(35, 151, 35);
}

.bar-status.not-reachable {
  color: rgb(247, 34, 34);
}

.card-hint {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #999;
}

.card-hint-item {
  margin: 0 2px;
}

.ant-tooltip .ant-tooltip-inner {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}
